<template>
  <b-container fluid>
               <b-row>
                  <b-col lg="12">
                     <iq-card>
                       <template v-slot:body>
                           Here Add Your HTML Content.....
                        </template>
                     </iq-card>
                  </b-col>
               </b-row>
  </b-container>
</template>
<script>
import { core } from '../../config/pluginInit'

export default {
  name: 'BlankPage',
  mounted () {
    core.index()
  }
}
</script>
